
import { defineComponent, computed } from '@vue/composition-api'

import { useAuthGetters } from '@/store'

const Default = () => import('@/layouts/default/default-layout.vue')
const Public = () => import('@/layouts/public/public-layout.vue')

import Notify from '@/components/notify/notify.vue'

export default defineComponent({
  name: 'app',
  components: {
    Notify,
  },
  setup: (props, { root }) => {
    const { isAuthenticated } = useAuthGetters()

    // add 'meta: { layout: exampleLayout }' to routes with a different layout
    const layout = computed(() =>
      root.$route?.meta?.layout ? root.$route.meta.layout : isAuthenticated.value ? Default : Public
    )
    return {
      layout,
    }
  },
})
