import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'

import store, { useNotify } from '@/store'

const { addNotification } = useNotify()

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    trackComponents: true,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.3,
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
    hooks: ['create', 'destroy', 'activate', 'mount', 'update'],
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        blockAllMedia: true,
      }),
    ],
    beforeSend(event, hint) {
      const currentUser = store.state.auth.currentUser

      event.user = {
        ...event.user,
        email: currentUser?.email || '',
        id: currentUser?.id.toString() || '',
      }

      const message =
        (hint?.originalException as any)?.userMessage || (hint?.originalException as any)?.message || 'Unbekannt'

      addNotification({
        type: 'error',
        text: `${message}`,
        sentryEventId: event.event_id,
      })

      return event
    },
  })
}
