
import { computed, defineComponent, ref } from '@vue/composition-api'
import * as Sentry from '@sentry/vue'

import { User } from '@/api/users'
import { mdiSend } from '@mdi/js'
import { ValidationForm, required, email as emailRule } from '@/utils/validation'
import { useNotify } from '@/store'

export default defineComponent({
  name: 'sentry-feedback-dialog',
  props: {
    eventId: {
      type: String,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()

    // Form Ref
    const sendFeedbackForm = ref<ValidationForm | null>(null)

    const currentUser = computed<User | null>(() => root.$store.state.auth.currentUser)

    // Input Refs
    const name = ref(currentUser.value ? `${currentUser.value.firstName} ${currentUser.value.lastName}` : '')
    const email = ref(currentUser.value ? currentUser.value.email : '')
    const feedback = ref('')

    const sendFeedback = () => {
      if (sendFeedbackForm.value?.validate()) {
        try {
          const userFeedback = {
            event_id: props.eventId,
            name: name.value,
            email: email.value,
            comments: feedback.value,
          }

          Sentry.captureUserFeedback(userFeedback)

          emit('close')
        } catch (error: unknown) {
          addNotification({ text: root.$t('feedback.form.error').toString(), type: 'error' })
        }
      }
    }

    return {
      icons: { mdiSend },
      sendFeedbackForm,
      required,
      name,
      email,
      emailRule,
      feedback,
      sendFeedback,
    }
  },
})
