import { useAxiosPaginated } from '@/plugins/axios'
import { PaginationParams } from '@/utils/pagination'

const version = 'v1'
const resource = 'rights'

export interface UserRight {
  authority: string
  description?: string
}

export enum Rights {
  ACTIVITY_CREATE = 'ACTIVITY_CREATE',
  ACTIVITY_DELETE_ALL = 'ACTIVITY_DELETE_ALL',
  ACTIVITY_DELETE_OWN = 'ACTIVITY_DELETE_OWN',
  ACTIVITY_READ_ALL = 'ACTIVITY_READ_ALL',
  ACTIVITY_READ_OWN = 'ACTIVITY_READ_OWN',
  ACTIVITY_UPDATE_ALL = 'ACTIVITY_UPDATE_ALL',
  ACTIVITY_UPDATE_OWN = 'ACTIVITY_UPDATE_OWN',
  API_DEVELOPER = 'API_DEVELOPER',
  CLIENT_CREATE = 'CLIENT_CREATE',
  CLIENT_DELETE = 'CLIENT_DELETE',
  CLIENT_READ = 'CLIENT_READ',
  CLIENT_UPDATE = 'CLIENT_UPDATE',
  CONFIRMATION_CREATE = 'CONFIRMATION_CREATE',
  CONFIRMATION_DELETE_ALL = 'CONFIRMATION_DELETE_ALL',
  CONFIRMATION_DELETE_OWN = 'CONFIRMATION_DELETE_OWN',
  CONFIRMATION_READ_ALL = 'CONFIRMATION_READ_ALL',
  CONFIRMATION_READ_OWN = 'CONFIRMATION_READ_OWN',
  CONFIRMATION_UPDATE_ALL = 'CONFIRMATION_UPDATE_ALL',
  CONFIRMATION_UPDATE_OWN = 'CONFIRMATION_UPDATE_OWN',
  INVOICE_CREATE_ALL = 'INVOICE_CREATE_ALL',
  INVOICE_DELETE_ALL = 'INVOICE_DELETE_ALL',
  INVOICE_READ_ALL = 'INVOICE_READ_ALL',
  INVOICE_UPDATE_ALL = 'INVOICE_UPDATE_ALL',
  INVOICE_CONFIRM = 'INVOICE_CONFIRM',
  INVOICE_CREATE_OWN = 'INVOICE_CREATE_OWN',
  INVOICE_DELETE_OWN = 'INVOICE_DELETE_OWN',
  INVOICE_READ_OWN = 'INVOICE_READ_OWN',
  INVOICE_UPDATE_OWN = 'INVOICE_UPDATE_OWN',
  MONTHLY_HOURS_CREATE = 'MONTHLY_HOURS_CREATE',
  MONTHLY_HOURS_DELETE = 'MONTHLY_HOURS_DELETE',
  MONTHLY_HOURS_READ_ALL = 'MONTHLY_HOURS_READ_ALL',
  MONTHLY_HOURS_READ_OWN = 'MONTHLY_HOURS_READ_OWN',
  MONTHLY_HOURS_UPDATE = 'MONTHLY_HOURS_UPDATE',
  ORDER_CREATE_ALL = 'ORDER_CREATE_ALL',
  ORDER_CREATE_OWN = 'ORDER_CREATE_OWN',
  ORDER_DELETE_ALL = 'ORDER_DELETE_ALL',
  ORDER_DELETE_OWN = 'ORDER_DELETE_OWN',
  ORDER_READ_ALL = 'ORDER_READ_ALL',
  ORDER_READ_OWN = 'ORDER_READ_OWN',
  ORDER_UPDATE_ALL = 'ORDER_UPDATE_ALL',
  ORDER_UPDATE_OWN = 'ORDER_UPDATE_OWN',
  PLAN_CREATE_ALL = 'PLAN_CREATE_ALL',
  PLAN_CREATE_OWN = 'PLAN_CREATE_OWN',
  PLAN_DELETE_ALL = 'PLAN_DELETE_ALL',
  PLAN_DELETE_OWN = 'PLAN_DELETE_OWN',
  PLAN_READ_ALL = 'PLAN_READ_ALL',
  PLAN_READ_INTERNALS = 'PLAN_READ_INTERNALS',
  PLAN_READ_OWN = 'PLAN_READ_OWN',
  PLAN_READ_SELLING_PRICE = 'PLAN_READ_SELLING_PRICE',
  PLAN_UPDATE_ALL = 'PLAN_UPDATE_ALL',
  PLAN_UPDATE_OWN = 'PLAN_UPDATE_OWN',
  PROJECT_CREATE = 'PROJECT_CREATE',
  PROJECT_DELETE = 'PROJECT_DELETE',
  PROJECT_READ = 'PROJECT_READ',
  PROJECT_READ_INTERNALS = 'PROJECT_READ_INTERNALS',
  PROJECT_UPDATE = 'PROJECT_UPDATE',
  PROJECT_UPDATE_ALL = 'PROJECT_UPDATE_ALL',
  PROJECT_UPDATE_OWN = 'PROJECT_UPDATE_OWN',
  RIGHT_READ = 'RIGHT_READ',
  RIGHT_UPDATE = 'RIGHT_UPDATE',
  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_DELETE = 'ROLE_DELETE',
  ROLE_READ = 'ROLE_READ',
  ROLE_UPDATE = 'ROLE_UPDATE',
  TRANSLATION_CREATE = 'TRANSLATION_CREATE',
  TRANSLATION_DELETE = 'TRANSLATION_DELETE',
  TRANSLATION_READ = 'TRANSLATION_READ',
  TRANSLATION_UPDATE = 'TRANSLATION_UPDATE',
  USER_CREATE = 'USER_CREATE',
  USER_DELETE = 'USER_DELETE',
  USER_DOCUMENT_CREATE = 'USER_DOCUMENT_CREATE',
  USER_DOCUMENT_DELETE = 'USER_DOCUMENT_DELETE',
  USER_DOCUMENT_READ_ALL = 'USER_DOCUMENT_READ_ALL',
  USER_DOCUMENT_READ_OWN = 'USER_DOCUMENT_READ_OWN',
  USER_DOCUMENT_UPDATE = 'USER_DOCUMENT_UPDATE',
  USER_READ = 'USER_READ',
  USER_READ_INTERNALS = 'USER_READ_INTERNALS',
  USER_READ_OWN = 'USER_READ_OWN',
  USER_READ_PHONE_NUMBER = 'USER_READ_PHONE_NUMBER',
  USER_UPDATE = 'USER_UPDATE',
  TEAM_CREATE = 'TEAM_CREATE',
  TEAM_READ = 'TEAM_READ',
  TEAM_UPDATE = 'TEAM_UPDATE',
  TEAM_DELETE = 'TEAM_DELETE',
  REPORTING_READ_ALL = 'REPORTING_READ_ALL',
  REPORTING_READ_OWN = 'REPORTING_READ_OWN',
}

export const useGetRights = () => {
  const axios = useAxiosPaginated<UserRight>({
    method: 'get',
    url: `/${version}/${resource}`,
  })

  const getRights = (paginationParams?: PaginationParams) => axios.exec({ params: paginationParams })

  return {
    ...axios,
    getRights,
  }
}
