import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
})

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#00b5d6',
        onPrimary: '#ffffff',
        secondary: '#5e5e5e',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        'written-hours': '#444',
        activity: '#dbf3fd',
      },
      dark: {
        primary: '#00b5d6',
        onPrimary: '#ffffff',
        secondary: '#5e5e5e',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        'written-hours': '#FFF',
        activity: '#4c7385',
      },
    },
  },
})

export default vuetify
