import Qs from 'qs'
import store from '@/store'
import router from '@/router'
import { computed, Ref, ref } from '@vue/composition-api'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { HateoasPagination, HateoasResponse } from '@/utils/hateoas-pagination'
import { Page, PaginationParams, PaginationResponse } from '@/utils/pagination'
import { Actions as AuthActions, Getters as AuthGetters } from '@/store/modules/auth'

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL || window.location.origin,
  paramsSerializer: function (params) {
    return Qs.stringify(params, { arrayFormat: 'repeat' })
  },
})

axiosInstance.interceptors.request.use((req) => {
  if (store.state.auth.accessToken) {
    // no-non-null-assertion due to typing bug in axios lib --> https://github.com/axios/axios/issues/4193
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    req.headers!.Authorization = `Bearer ${store.state.auth.accessToken}`
  }
  return req
}, Promise.reject)

axiosInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.status === 401 && store.getters[AuthGetters.isAuthenticated]) {
      store.dispatch(AuthActions.logout).then(() => {
        if (router.currentRoute.name !== 'login') router.push('/login')
      })

      return null
    } else {
      return Promise.reject(err)
    }
  }
)

// export interface UseAxios<T> {
//   data: Ref<T | null>
//   status: Ref<number>
//   statusText: Ref<string>
//   isLoading: Ref<boolean>
//   error: Ref<any>
//   exec: (config?: AxiosRequestConfig, throwException?: boolean) => Promise<T>
// }

export const useAxios = <T = any>(baseCfg: AxiosRequestConfig = {}, instance = axiosInstance) => {
  const status = ref(0)
  const statusText = ref('')

  const data: Ref<T | null> = ref(null)

  const isLoading = ref(false)

  const error = ref<any>(null)

  const exec = async (cfg: AxiosRequestConfig = {}, throwException = true) => {
    isLoading.value = true
    try {
      const result: AxiosResponse<T> = await instance.request({ ...baseCfg, ...cfg })
      data.value = result?.data
      status.value = result?.status
      return result?.data
    } catch (e) {
      error.value = e
      if (throwException) throw e
      return Promise.reject(e)
    } finally {
      isLoading.value = false
    }
  }

  return {
    data,
    status,
    statusText,
    isLoading,
    error,
    exec,
  }
}

export interface UseAxiosPaginated<T> {
  data: Ref<T[]>
  status: Ref<number>
  statusText: Ref<string>
  isLoading: Ref<boolean>
  error: Ref<any>
  paginationParams: Ref<PaginationParams>
  exec: (config?: AxiosRequestConfig, throwException?: boolean) => Promise<T[]>
}

const defaultPaginationResponse: PaginationResponse = {
  empty: false,
  first: false,
  last: false,
  number: 0,
  numberOfElements: 0,
  pageable: {
    offset: 0,
    pageNumber: 0,
    pageSize: 0,
    paged: false,
    sort: {
      sorted: false,
      unsortet: false,
      empty: false,
    },
    unpaged: false,
  },
  size: 0,
  sort: {
    sorted: false,
    unsortet: false,
    empty: false,
  },
  totalElements: 0,
  totalPages: 0,
}

export const useAxiosPaginated = <T = any>(baseCfg: AxiosRequestConfig = {}, instance = axiosInstance) => {
  const paginationResponse = ref<PaginationResponse>(defaultPaginationResponse)

  const data: Ref<T[]> = ref([])

  const axios = useAxios<Page<T>>(baseCfg, instance)

  const exec = async (cfg: AxiosRequestConfig = {}, throwException = true) =>
    axios.exec(cfg, throwException).then((response) => {
      const { content, ...paginationParams } = response
      data.value = content
      paginationResponse.value = paginationParams
      return content
    })

  return {
    ...axios,
    data,
    exec,
    paginationResponse,
  }
}

export const useAxiosHateoasPaginated = <T = any>(baseCfg: AxiosRequestConfig = {}, instance = axiosInstance) => {
  const paginationResponse = ref<HateoasPagination>(defaultPaginationResponse)

  const data: Ref<T[]> = ref([])

  const axios = useAxios<HateoasResponse<T>>(baseCfg, instance)

  const exec = async (cfg: AxiosRequestConfig = {}, throwException = true) => {
    const res = await axios.exec(cfg, throwException)
    const { content, ...pagination } = res
    data.value = content
    paginationResponse.value = pagination
  }

  return {
    ...axios,
    data,
    exec,
    paginationResponse,
    total: computed(() => paginationResponse.value.totalElements),
  }
}
