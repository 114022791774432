import router from '@/router'
import { User } from '@/api/users'
import { Rights } from '@/api/rights'
import { i18n } from '@/plugins/i18n'
import store, { RootState } from '@/store'
import { axiosInstance } from '@/plugins/axios'
import { IAxiosError } from '@/plugins/hateoas-client'
import { LoginCredentials, TokenResponse } from '@/api/auth'
import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'

export interface Token {
  exp: number
  family_name: string
  given_name: string
  iat: number
  iss: string
  nonce: string
  sub: string
  uid: number
}

// https://stackoverflow.com/a/38552302/3112139
const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  )

  const parsedToken: Token = JSON.parse(jsonPayload)

  return { expiringIn: new Date(parsedToken.exp * 1000).getTime() - new Date().getTime(), ...parsedToken }
}

export interface AuthState {
  currentUser: User | null
  tokenRefresh: NodeJS.Timeout | null
  accessToken: string
}

export const state: AuthState = {
  accessToken: '',
  tokenRefresh: null,
  currentUser: null,
}

export enum Getters {
  userRights = 'auth/userRights',
  isAuthenticated = 'auth/isAuthenticated',
  hasRight = 'auth/hasRight',
  currentUser = 'auth/currentUser',
}

export const getters: GetterTree<AuthState, RootState> = {
  userRights: (state) => {
    return state.currentUser?.roles.flatMap((role) => role.rights.map((right) => right.authority)) || ['']
  },
  isAuthenticated: (state) => {
    return !!state.accessToken
  },
  hasRight: (state) => (right: Rights) =>
    state.currentUser?.roles.flatMap((role) => role.rights.map((right) => right.authority)).includes(right) || false,
  currentUser: (state) => state?.currentUser || null,
}

enum Mutations {
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  SET_TOKEN_REFRESH = 'SET_TOKEN_REFRESH',
}

export const mutations: MutationTree<AuthState> = {
  SET_CURRENT_USER(state, user: User | null) {
    state.currentUser = user
  },
  SET_ACCESS_TOKEN(state, token: string | null) {
    state.accessToken = token || ''
    if (token) {
      localStorage.setItem('accessToken', token)
    } else {
      localStorage.removeItem('accessToken')
    }
  },
  SET_TOKEN_REFRESH(state, { token, refreshFkt }: { token: string | null; refreshFkt?: () => void }) {
    if (state.tokenRefresh) {
      clearTimeout(state.tokenRefresh)
    }
    if (token && refreshFkt) {
      const { expiringIn } = parseJwt(token)

      //set timeout to env var or 30 mins
      state.tokenRefresh = setTimeout(
        refreshFkt,
        expiringIn -
          (process.env.VUE_APP_TOKEN_MIN_VALIDITY ? parseInt(process.env.VUE_APP_TOKEN_MIN_VALIDITY) : 30 * 60 * 1000)
      )
    }
  },
}

export enum Actions {
  getCurrentUser = 'auth/getCurrentUser',
  setCurrentUser = 'auth/setCurrentUser',
  login = 'auth/login',
  ssoLogin = 'auth/ssoLogin',
  setRefresh = 'auth/setRefresh',
  logout = 'auth/logout',
  init = 'auth/init',
}

export const actions: ActionTree<AuthState, RootState> = {
  /**
   * Action that fetches the current user and commits it.
   * Automatically logs the user out and redirects to login if it fails.
   *
   * Will be executed on route load if no user has been fetched yet (in @/router/index.ts).
   */
  getCurrentUser({ dispatch }) {
    return axiosInstance.get<User>('/v1/users/me').then((response) => {
      dispatch('setCurrentUser', response.data)
      return response.data
    })
  },
  /**
   * Action that commits the given user as current user.
   * Used i.e. if the user changes his profile.
   */
  setCurrentUser({ commit }, user: User | null) {
    commit(Mutations.SET_CURRENT_USER, user)
    store.dispatch('locale/setCurrentLocale', user?.locale)
  },
  /**
   * Action that tries to log the user in with the given credentials.
   * @param param0
   * @param param1 LoginCredentials
   */
  async login({ dispatch, commit }, { username, password }: LoginCredentials) {
    try {
      const token = (
        await axiosInstance
          .post('/auth/token', { username, password })
          .then((response) => response.data as TokenResponse)
      ).token

      commit(Mutations.SET_ACCESS_TOKEN, token)
      dispatch('setRefresh', token)
    } catch (error: unknown) {
      const axiosError = error as IAxiosError
      axiosError.userMessage = i18n.t('login.error').toString()
      throw error
    }
  },
  ssoLogin({ dispatch, commit }, token: string) {
    commit(Mutations.SET_ACCESS_TOKEN, token)
    dispatch('setRefresh', token)
  },
  /**
   * Action that commits the SET_TOKEN_REFRESH mutation.
   * Passes refreshFkt that is executed when the token expires.
   * @param param0
   * @param token string | null
   */
  setRefresh({ commit, dispatch }, token: string | null) {
    if (token) {
      // Function that refreshes the token
      const refreshFkt = () => {
        axiosInstance
          .post<TokenResponse>('/auth/renew')
          .then((response) => {
            if (response.data.token) {
              commit(Mutations.SET_ACCESS_TOKEN, response.data.token)
              dispatch('setRefresh', response.data.token)
            }
          })
          .catch((error) => {
            dispatch('logout')
            router.push('/login')
            throw error
          })
      }
      commit(Mutations.SET_TOKEN_REFRESH, { token, refreshFkt })
    } else {
      commit(Mutations.SET_TOKEN_REFRESH, { token: null })
    }
  },
  /**
   * Action that logs out the user.
   * All data is being reset.
   * @param param0
   */
  logout({ commit }) {
    if (router.currentRoute.name !== 'login') router.push('/login')

    commit(Mutations.SET_CURRENT_USER, null)
    commit(Mutations.SET_ACCESS_TOKEN, null)
    commit(Mutations.SET_TOKEN_REFRESH, { token: null })

    return Promise.resolve()
  },
  /**
   * Action that inits this module with the token stored in localStorage.
   * @param param0
   */
  init({ commit, dispatch }) {
    const token = localStorage.getItem('accessToken')
    if (token && parseJwt(token).expiringIn >= 0) {
      commit(Mutations.SET_ACCESS_TOKEN, token)
      dispatch('setRefresh', token)
    } else if (router.currentRoute.name === null) {
      // don't logout if you are on the login page, fix #234
      // calling router.push() will cause an vue-composition-api error
      return
    } else {
      dispatch('logout')
    }
  },
}

const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default auth
