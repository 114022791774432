import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'

Vue.use(VueI18n)

const fallbackLocale = 'de'

const commonLocaleMap = {
  en: 'en_US',
  de: 'de_DE',
  fr: 'fr_FR',
  es: 'es_ES',
}

const messages: VueI18n.LocaleMessages = {}

export function loadLanguageAsync(lang: string) {
  if (i18n.locale !== lang) {
    if (!messages[lang]) {
      // should not require auth as there are also pages not requiring it
      return axios
        .get('/v1/translations/' + lang.toLowerCase())
        .then(({ data }) => {
          i18n.setLocaleMessage(lang, data.default)
          messages[lang] = data
          return setI18nLanguage(lang)
        })
        .catch(() => {
          return setI18nLanguage(fallbackLocale)
        })
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}

function setI18nLanguage(lang: string) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  const doc = document && document.querySelector('html')
  if (doc) {
    doc.setAttribute('lang', lang)
  }
  return lang
}

export const i18n = new VueI18n({
  messages: messages,
})

export const resolveCountry = (lang: string) => {
  return commonLocaleMap[lang] || ''
}
