function isOfType<T>(
  variableToBeChecked: unknown,
  propertyToCheckFor: keyof T | (keyof T)[]
): variableToBeChecked is T {
  if (Array.isArray(propertyToCheckFor)) {
    const validationArray = propertyToCheckFor.map((property) => {
      return (variableToBeChecked as T)[property] !== undefined
    })

    return validationArray.some((entry) => entry)
  } else {
    return (variableToBeChecked as T)?.[propertyToCheckFor] !== undefined
  }
}

export { isOfType }
