import Vue from 'vue'
import Vuex from 'vuex'
import notify, { NotifyState } from './modules/notify'
import auth, { AuthState } from './modules/auth'
import locale, { LocaleState } from './modules/locale'

import { Actions as AuthActions } from './modules/auth'
import { Actions as LocaleActions } from './modules/locale'

Vue.use(Vuex)

export interface RootState {
  notify: NotifyState
  auth: AuthState
  locale: LocaleState
}

const store = new Vuex.Store<RootState>({
  modules: {
    notify,
    auth,
    locale,
  },
})

// dispatch init actions if necassary
store.dispatch(AuthActions.init)
store.dispatch(LocaleActions.init)

export default store

// export composition api helpers for all getters and setters
export { useAuthActions, useAuthGetters } from './modules/auth/useAuthModule'
export { useLocaleActions } from './modules/locale/useLocaleModule'
export { useNotify } from './modules/notify/useNotifyModule'
