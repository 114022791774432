import { computed, ComputedRef } from '@vue/composition-api'
import Router from 'vue-router'
import routes from './routes'
import store, { useLocaleActions } from '@/store'
import { useAuthGetters, useAuthActions } from '@/store'
import { loadLanguageAsync, i18n } from '@/plugins/i18n'
import { User } from '@/api/users'

const PAGE_TITLE_PREFIX = process.env.VUE_APP_NAME

const router = new Router({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  const { isAuthenticated, userRights } = useAuthGetters(store)

  const { getCurrentUser, logout } = useAuthActions(store)

  const { init } = useLocaleActions()

  const isLocaleInitialized: ComputedRef<boolean> = computed(() => store.state.locale.isLocaleInitialized)

  const currentLanguage: ComputedRef<string> = computed(() => store.state.locale.currentLocale)
  const currentUser: ComputedRef<User | null> = computed(() => store.state.auth.currentUser)

  if (!isLocaleInitialized.value) {
    await init()
  }

  // Fetch the user on application relaod
  if (isAuthenticated.value && !currentUser.value) {
    getCurrentUser().then(() => {
      checkPermission()
    })
  } else {
    checkPermission()
  }

  function checkPermission() {
    const authRequired = to.matched.some((route) => route.meta.authRequired)

    //If no auth required just goNext()
    if (!authRequired || process.env.VUE_APP_DISABLE_AUTH) {
      goNext()
    }
    //Auth required
    else {
      //Auth required but not authenticated
      if (!isAuthenticated.value) {
        logout()
        return
      }
      //Check if user has required rights
      else {
        if (!to.meta?.permission) {
          goNext()
        } else if (
          Array.isArray(to.meta?.permission) &&
          to.meta.permission.some((permission) => userRights.value.includes(permission))
        ) {
          goNext()
        } else if (!Array.isArray(to.meta?.permission) && userRights.value.includes(to.meta.permission)) {
          goNext()
        } else goNext('/')
      }
    }
  }

  function goNext(newLocation: string | void) {
    loadLanguageAsync(currentLanguage.value).then(() => next(newLocation))
  }
})

router.afterEach((to) => {
  document.title = PAGE_TITLE_PREFIX + (to.meta?.title ? ` - ${i18n.t(to.meta.title)}` : '')
})

export default router
