import { Rights } from '@/api/rights'
import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(
        /* webpackChunkName: "Login" */
        /* webpackPrefetch: true */ '@/views/login/login-view.vue'
      ),
  },
  /*   {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact/ContactView.vue'),
    meta: {
      title: 'contact.title',
      authRequired: true,
      // use USER_READ so freelancer cannot use contact view
      permission: Rights.USER_READ,
    },
  }, */
  {
    path: '/signUp',
    name: 'signUp',
    component: () => import(/* webpackChunkName: "SignUp" */ '@/views/signUp/sign-up-view.vue'),
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    props: true,
    component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/password/reset-password-view.vue'),
  },
  {
    path: '/setPassword',
    name: 'setPassword',
    props: (route) => ({ token: route.query.token }),
    component: () => import(/* webpackChunkName: "SetPassword" */ '@/views/password/set-password-view.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "Profile" */ '@/views/profile/profile-view.vue'),
    meta: {
      title: 'profile.title',
      authRequired: true,
    },
  },
  {
    path: '/timetracking',
    name: 'timetracking',
    component: () => import(/* webpackChunkName: "timetracking" */ '@/views/timetracking/timetracking-overview.vue'),
    meta: {
      title: 'timetracking.title',
      authRequired: true,
    },
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import(/* webpackChunkName: "documents" */ '@/views/documents/user-documents.vue'),
    meta: {
      title: 'document.title',
      authRequired: true,
      permission: [Rights.USER_DOCUMENT_READ_OWN, Rights.USER_DOCUMENT_READ_ALL],
    },
  },
  {
    path: '/reporting/summary',
    name: 'reportingSummary',
    component: () => import('@/views/reporting/reporting-summary-overview.vue'),
    meta: {
      title: 'reporting.title',
      authRequired: true,
    },
  },
  {
    path: '/reporting/monthly',
    name: 'reportingMonthly',
    component: () => import('@/views/reporting/reporting-monthly-overview.vue'),
    meta: {
      title: 'reporting.title',
      authRequired: true,
    },
  },
  {
    path: '/admin/users',
    name: 'adminUsers',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/users/admin-users-view.vue'),
    meta: {
      title: 'admin.users.title',
      authRequired: true,
      permission: Rights.USER_READ,
    },
  },
  {
    path: '/admin/rights',
    name: 'adminRights',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/rights/admin-rights-view.vue'),
    meta: {
      title: 'admin.rights.title',
      authRequired: true,
      permission: Rights.RIGHT_READ,
    },
  },
  {
    path: '/admin/roles',
    name: 'adminRoles',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/roles/admin-roles-view.vue'),
    meta: {
      title: 'admin.roles.title',
      authRequired: true,
      permission: Rights.ROLE_READ,
    },
  },
  {
    path: '/admin/translations',
    name: 'adminTranslations',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/translations/admin-translations-view.vue'),
    meta: {
      title: 'admin.translations.title',
      authRequired: true,
      permission: Rights.TRANSLATION_READ,
    },
  },
  {
    path: '/admin/manage-hours',
    name: 'adminManageHours',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/manageHours/admin-manage-hours.vue'),
    meta: {
      title: 'admin.manageHours.title',
      authRequired: true,
      permission: Rights.USER_UPDATE,
    },
  },
  {
    path: '/admin/manage-hours/:userId',
    name: 'adminManageHoursUser',
    props: (route) => ({ userId: +route.params.userId }),
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/manageHours/admin-user-hours.vue'),
    meta: {
      authRequired: true,
      permission: Rights.USER_UPDATE,
    },
  },
  {
    path: '/admin/teams',
    name: 'adminTeams',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/teams/team-overview.vue'),
    meta: {
      title: 'team.title',
      authRequired: true,
      permission: Rights.TEAM_READ,
    },
  },
  {
    path: '/admin/documents',
    name: 'adminDocuments',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/documents/admin-documents.vue'),
    meta: {
      title: 'admin.documents.title',
      authRequired: true,
      permission: Rights.USER_DOCUMENT_READ_ALL,
    },
  },
  {
    path: '/project/projects',
    name: 'projectProjects',
    component: () => import(/* webpackChunkName: "project" */ '@/views/project/project-overview.vue'),
    meta: {
      title: 'project.title',
      authRequired: true,
      permission: Rights.PROJECT_READ,
    },
  },
  {
    path: '/project/projects/:id',
    name: 'projectProjectsDetails',
    component: () => import(/* webpackChunkName: "project" */ '@/views/project/project-detail.vue'),
    meta: {
      title: 'project.title',
      authRequired: true,
      permission: [Rights.PROJECT_READ, Rights.PROJECT_READ_INTERNALS],
    },
    props: (route) => ({ id: +route.params.id }),
  },
  {
    path: '/project/clients',
    name: 'projectClients',
    component: () => import(/*webpackChunkName: "client" */ '@/views/client/client-overview.vue'),
    meta: {
      title: 'client.title',
      authRequired: true,
      permission: Rights.CLIENT_READ,
    },
  },
  {
    path: '/project/plans',
    name: 'projectPlans',
    component: () => import(/* webpackChunkName: "plan" */ '@/views/plan/plan-overview.vue'),
    meta: {
      title: 'plan.title',
      authRequired: true,
      permission: [Rights.PLAN_READ_ALL, Rights.PLAN_READ_OWN],
    },
  },
  {
    path: '/project/plans/create',
    name: 'projectPlansCreate',
    component: () => import('@/views/plan/plan-create.vue'),
    meta: {
      title: 'plan.title',
      authRequired: true,
      permission: [Rights.PLAN_CREATE_ALL, Rights.PLAN_CREATE_OWN],
    },
  },
  {
    path: '/project/invoices',
    name: 'projectInvoices',
    component: () => import(/*webpackChunkName: "client" */ '@/views/invoice/invoice-overview.vue'),
    meta: {
      title: 'invoice.title',
      authRequired: true,
      permission: [Rights.INVOICE_READ_ALL, Rights.INVOICE_READ_OWN],
    },
  },
  {
    path: '/project/orders',
    name: 'projectOrders',
    component: () => import(/*webpackChunkName: "client" */ '@/views/order/order-overview.vue'),
    meta: {
      title: 'order.title',
      authRequired: true,
      permission: [Rights.ORDER_READ_OWN, Rights.ORDER_READ_ALL],
    },
  },
  {
    path: '/project/activities',
    name: 'projectActivities',
    component: () => import(/*webpackChunkName: "client" */ '@/views/activity/activity-overview.vue'),
    meta: {
      title: 'activity.title',
      authRequired: true,
    },
  },
  {
    path: '*',
    redirect: '/timetracking',
  },
]

export default routes
