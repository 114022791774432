import { i18n } from '@/plugins/i18n'
import { Activity, ActivityInput, Plan, PlanInput } from '@/api/interfaces'
import { isOfTypeWithValue } from './isOfTypeWithValue'
import { isOfType } from './isOfType'

export interface ValidationForm {
  reset: () => void
  resetValidation: () => void
  validate: () => boolean
}

export function required(value: unknown, name = '') {
  if (Array.isArray(value)) return value.length !== 0 || i18n.t('form.validation.required', { name }).toString()
  if (Number.isFinite(value)) return true
  return !!value || i18n.t('form.validation.required', { name }).toString()
}

export function email(value: string, name = '') {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(value) || (i18n.t('form.validation.email', { name }) as string)
}

export function passwordsMatch(password: string, repeatPassword: string) {
  return password === repeatPassword || i18n.t('form.validation.passwordsMatch').toString()
}

export function positiveNumber(value: any, name = '', inclusive = true) {
  return (
    (!isNaN(value) && inclusive ? value >= 0 : value > 0) ||
    i18n.t('form.validation.positiveNumber', { name }).toString()
  )
}

export function workingTimeValidation(begin: string, end: string) {
  return (
    parseInt(begin.replace(':', ''), 10) < parseInt(end.replace(':', ''), 10) ||
    i18n.t('form.validation.workingTime').toString()
  )
}

export function projectPlansFrom(plans: Plan[], projectDateFrom: string) {
  if (
    plans.some((plan) => {
      if (plan.from < projectDateFrom) return true
    })
  ) {
    return i18n.t('form.validation.projectPlansFrom')
  }
  return true
}
export function projectPlansTo(plans: Plan[], projectDateTo: string) {
  if (
    plans.some((plan) => {
      if (plan.to > projectDateTo) return true
    })
  ) {
    return i18n.t('form.validation.projectPlansTo')
  }
  return true
}

export function planActivitiesFrom(activities: Activity[], planDateFrom: string) {
  if (
    activities.some((activity) => {
      if (activity.date < planDateFrom) return true
    })
  ) {
    return i18n.t('form.validation.planActivitiesFrom')
  }
  return true
}

export function planActivitiesTo(activities: Activity[], planDateTo: string) {
  if (
    activities.some((activity) => {
      if (activity.date > planDateTo) return true
    })
  ) {
    return i18n.t('form.validation.planActivitiesTo')
  }
  return true
}

export function hoursBudgetValidation(plan: PlanInput | Plan) {
  if (isOfTypeWithValue<Plan, PlanInput>(plan, 'id')) {
    if (plan.bookedHours > plan.hoursBudget) {
      return i18n.t('form.validation.hoursBudget')
    }
  }
  return true
}

export function isValidHour(
  hours: number,
  plans: Plan[],
  activityInternal: Activity | ActivityInput,
  activity?: Activity | ActivityInput
) {
  if (hours === 0) return i18n.t('form.validation.hoursGreaterZero')

  const plan = plans?.find((plan) => plan.id === activityInternal.planId)
  if (!plan) return false

  const budget = plan?.hoursBudget - plan?.bookedHours

  //Type activity means its editMode
  const offset =
    isOfType<Activity>(activity, 'projectId') && activityInternal.planId === activity.planId ? activity.hours : 0

  const inBudget = budget - hours + offset >= 0
  if (!inBudget) return i18n.t('form.validation.budget', { budget }).toString()

  const inStepSize = (hours * 1000) % (plan.hoursStepSize * 1000) === 0
  if (!inStepSize || hours < 0) return i18n.t('form.validation.stepSize', { step: plan.hoursStepSize })

  return true
}

export const useValidation = () => {
  return {
    email,
    required,
    isValidHour,
    positiveNumber,
    passwordsMatch,
    workingTimeValidation,
  }
}
