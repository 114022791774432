/* eslint-disable @typescript-eslint/no-non-null-assertion */
import vuexStore from '@/store'
import { computed } from '@vue/composition-api'

import { User } from '@/api/users'
import { LoginCredentials } from '@/api/auth'
import { Rights } from '@/api/rights'

import { Actions, Getters, Token } from './index'

export const useAuthGetters = (store = vuexStore) => {
  const userRights = computed((): string[] => store.getters[Getters.userRights])

  const isAuthenticated = computed((): boolean => store.getters[Getters.isAuthenticated])

  const hasRight = computed((): ((right: Rights) => boolean) => store.getters[Getters.hasRight])

  const currentUser = computed((): User => store.getters[Getters.currentUser])
  const isProjectManager = computed(() => (store.getters[Getters.currentUser].managerProjectIds ? true : false))

  const projectsAsManager = computed((): number[] => {
    if (store.getters[Getters.currentUser].managerProjectIds) {
      return store.getters[Getters.currentUser].managerProjectIds
    }
    return []
  })

  return {
    userRights,
    isAuthenticated,
    hasRight,
    currentUser,
    isProjectManager,
    projectsAsManager,
  }
}

export const useAuthActions = (store = vuexStore) => {
  const getCurrentUser = (): Promise<void> => {
    return store.dispatch(Actions.getCurrentUser)
  }

  const setCurrentUser = (user: User | null): void => {
    store.dispatch(Actions.setCurrentUser, user)
  }

  const login = (credentials: LoginCredentials): Promise<void> => {
    return store.dispatch(Actions.login, credentials)
  }
  const ssoLogin = (token: string): Promise<void> => {
    return store.dispatch(Actions.ssoLogin, token)
  }

  const logout = (): Promise<void> => {
    return store.dispatch(Actions.logout)
  }

  const init = () => {
    store.dispatch(Actions.init)
  }

  return {
    getCurrentUser,
    setCurrentUser,
    login,
    ssoLogin,
    logout,
    init,
  }
}
