import Vue from 'vue'
import App from './app.vue'

import store from '@/store'

import Router from 'vue-router'
import router from './router'
Vue.use(Router)

import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)

// Globally register all `base`-prefixed components
import '@/components/base/globals'

// Plugins
import { i18n } from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import '@/plugins/sentry'

/**
 * use "yarn dev:tools" to connect to external devtools instead of the browser plugin
 */
import devtools from '@vue/devtools'
if (process.env.VUE_APP_DEV === 'devtools') {
  devtools.connect('localhost', '8090')
}

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

/**
 * Attach app to window in e2e tests
 * so that e2e tests can use the vuex login / logout function
 */
if (process.env.VUE_APP_TEST === 'e2e') {
  ;(window as any).__app__ = app
}
